import $ from 'jquery';

export function initPageheader() {
	// pageheader sticky on scroll
	const header = $('.pageheader');
	if (!header.length) return;

	const onscroll = () => {
		if (window.pageYOffset > 0) {
			header.addClass('sticky');
		} else {
			header.removeClass('sticky');
		}
	};

	window.onscroll = onscroll;
	onscroll(); // first time
}

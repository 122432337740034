import $ from 'jquery';
// import './toggle.css';

const toggleAttribute = 'data-toggle';
const initialState = 'data-toggle-initial'; // todo initialState is not used in toggle thingy
const stateAttribute = 'data-toggle-state';
const groupAttribute = 'data-toggle-group';
const toggleScrollAttribute = 'data-toggle-scroll';
const closed = 'closed';
const opened = 'opened';
const duration = 400;

function toggle($trigger, close) {
	const $target = $($trigger.attr(toggleAttribute));
	const $header = $('.pageheader');
	const isOpened = close || $target.is(':visible');
	const scrollToTarget = 'undefined' !== typeof $trigger.attr(toggleScrollAttribute);

	$trigger.attr(stateAttribute, isOpened ? closed : opened);
	$target[isOpened ? 'hide' : 'show'](duration);

	// autofocus after 100ms to overcome animation bug
	if (!isOpened) {
		setTimeout(() => {
			$target.find('input').focus();
		}, 100);

		if (scrollToTarget) {
			setTimeout(() => {
				// include sticky header
				let headerHeight = parseInt($header.height(), 10) + parseInt($header.css('top'), 10);

				$([document.documentElement, document.body]).animate({
					scrollTop: Math.floor($target.offset().top - $trigger.outerHeight() - headerHeight),
				}, duration);
			}, duration);
		}
	}
}

export function initializeToggle(element = `[${toggleAttribute}]`) {
	const selector = element === `[${toggleAttribute}]` ? null : `[${toggleAttribute}]`;

	$(element).on('click', selector, event => {
		event.preventDefault();
		const $trigger = $(event.currentTarget);
		const $otherTriggersInGroup = $(`[${groupAttribute}]`).not($trigger);

		// close all other elements in the group
		$otherTriggersInGroup.each((index, otherTrigger) => {
			toggle($(otherTrigger), true);
		});

		toggle($trigger);
	});
}

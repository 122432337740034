import $ from 'jquery';
import {initializeFilterDropdown} from './filter';
import {initializeSlider} from './slider';
import {initializeToggle} from './toggle';
import {initializeGallery} from './gallery';
import {initializePartners} from './partner';
import {initMisc} from './misc';
import {initTracking} from './tracking';
import {initPageDotTracking} from './tracking/pagedot';
import {initPageheader} from './pageheader';
import {initNewsletterOverlay} from './overlay/overlay-newsletter';
import {initProductContactOverlay} from './overlay/overlay-product-contact';
import {handleProductContactForm} from './overlay/product-contact';
import {initGlossarOverlay} from './overlay/overlay-glossar';

$(() => {
	initializeToggle();
	initializeSlider();
	initializeFilterDropdown();
	initializeGallery();
	initializePartners();
	initMisc();
	initTracking();
	initPageDotTracking();
	initPageheader();
	initNewsletterOverlay();
	initProductContactOverlay();
	handleProductContactForm();
	initGlossarOverlay();
});
